<template>
  <div>
    <UsersTableComponent
      v-if="pSelectedHost"
      :pDisplayItems="displayUsersByHost"
      :pCurrentPageLoading="currentPageLoading"
      :pTotalCount="totalCount"
      :pLocalStorageTable="localStorageTableSizeKey"
      :pStoreRef="'hostUsersModule'"
      :pShowHostName="false"
      :pShowUserActions="user.isUserAdminHost(pSelectedHost) || user.isSuperAdmin"
      @onLoadPage="loadPage($event)"
      @onOpenEditDialog="openEditUserDialog($event)"
    />
    <UserDialog
      ref="editUserDialog"
      :pStoreRef="'hostUsersModule'"
      @onAddOrSave="onAddOrSaveUser"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import { dismissAction, logAndExtractMessage } from "@/utils/utils";

export default {
  name: "UsersByHost",

  components: {
    UsersTableComponent: () => import("../custom-components/UsersTableComponent.vue"),
    UserDialog: () => import("../dialogs/UserDialog.vue"),
  },

  props: {
    pSelectedHost: Object,
  },

  data: () => ({
    localStorageTableSizeKey: "users.of.host.table.size",
  }),

  computed: {
    ...mapState("hostUsersModule", [
      "currentPageLoading",
      "currentPage",
      "displayUsersByHost",
      "totalCount",
    ]),
    ...mapState("userModule", ["user"]),
  },

  methods: {
    async loadPage(currentPage) {
      const sentCurrentPage = {
        ...currentPage,
        hostId: this.pSelectedHost.id,
      };

      try {
        await this.$store.dispatch("hostUsersModule/loadPage", sentCurrentPage);
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openEditUserDialog(user) {
      if (!Object.keys(user).length) {
        this.$refs.editUserDialog.openDialog({
          hostMemberships: [{
            id: undefined,
            host: this.pSelectedHost,
            roles: [],
          }],
        }, { disableAddDeleteHostMemberships: true });
      } else {
        this.$refs.editUserDialog.openDialog(user);
      }
    },

    async onAddOrSaveUser(user) {
      if (
        user.id === this.user.id
        && this.$refs.editUserDialog.userRequestedToEdit.hostMemberships.length
        && !user.hostMemberships.length
        && this.$route.path === "/active-host"
      ) {
        await this.$router.push("/profile");
        return;
      }

      this.$emit("update", user);
    },
  },

  mounted() {
    this.$emit("onMount");
  },
};
</script>
